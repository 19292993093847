import { createI18n } from 'vue-i18n'
import de from './labels-de.json'
import en from './labels-en.json'

export default createI18n({
  legacy: false,
  locale: 'de',
  messages: {
    de,
    en,
  },
})

import { Roles } from '~/config/constants'

export function isValidUrl(urlString: string): boolean {
  if (urlString?.length > 200)
    return false

  const urlPattern = new RegExp('^(https?:\\/\\/)?' // validate protocol
    + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // validate domain name
    + '((\\d{1,3}\\.){3}\\d{1,3}))' // validate OR ip (v4) address
    + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // validate port and path
    + '(\\?[;&a-z\\d%_.~+=-]*)?' // validate query string
    + '(\\#[-a-z\\d_]*)?$', 'i') // validate fragment locator
  return !!urlPattern.test(urlString)
}

export function isNumber(input: string | number): boolean {
  const regex = /^\d+$/
  return regex.test(input)
}

export function isNullOrMaxTwoDigitInteger(input: string | number): boolean {
  if (typeof input !== 'string' && typeof input !== 'number' && input !== null)
    throw new TypeError(`Unexpected input ${input}`)

  if (typeof input === 'string' && input?.length > 2)
    return false

  if (typeof input === 'number' && input?.toString().length > 2)
    return false

  if (!input)
    return true

  return isNumber(input)
}

export function isNotNullOrOverThreeDigitInteger(input: string | number | null): boolean {
  if (input === null || input === undefined) {
    return false
  }
  if (typeof input !== 'string' && typeof input !== 'number' && input !== null) {
    throw new TypeError(`Unexpected input ${input}`)
  }

  if (typeof input === 'string' && input?.length > 3) {
    return false
  }

  if (typeof input === 'number' && input?.toString().length > 3) {
    return false
  }
  if (!input) {
    return false
  }
  return isNumber(input)
}

export function isValidRole(roleString: string): boolean {
  return Object.values(Roles).includes(roleString)
}

export function isUnderMinOrOverMaxLength(textField: string | null | undefined, minLength: number, maxLength: number) {
  if (textField === undefined && minLength > 0) {
    return true
  }
  return (textField?.length < minLength) || (maxLength && textField?.length > maxLength) || (textField === null && minLength > 0)
}

export function handleTextfieldValidation(
  textField: string,
  textFieldOrig: string,
  fieldKey: string,
  index: number,
  updateElementValue: (rowIndex: number, key: string, newValue: string) => void,
  setIsChangedStatus: (rowIndex: number) => void,
  minLength: number,
  maxLength?: number,
) {
  if (isUnderMinOrOverMaxLength(textField, minLength, maxLength)) {
    updateElementValue(index, fieldKey, JSON.parse(JSON.stringify(textFieldOrig)))
  }
  else {
    setIsChangedStatus(index)
  }
}

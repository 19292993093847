import type { CookieRef } from '#app'
import type { UserPayloadInterface } from '~/interfaces/interfaces'
import { useRuntimeConfig } from '#imports'
import { jwtDecode } from 'jwt-decode'
import { defineStore } from 'pinia'
import { ZEUS_TOKEN, ZEUS_USER_INFO } from '~/config/constants'
import { isValidUrl } from '~/utils/validate'
import { getCookie, parseCookie } from '../utils/route-handler'
import i18n from '/locale/i18n'

const { t } = i18n.global
interface State {
  loading: boolean
  userInfo: {
    firstName: string
    lastName: string
    id: number
    roles: string
  } | CookieRef<string | null | undefined>
  token: string | null
  error: {
    data: {
      message: string | null
    }
  }
  baseUrl: string | null
  loginEndpoint: string | null

}

function getCookieValue(cookieName) {
  const cookies = parseCookie(document.cookie)
  if (cookies !== null && cookies[cookieName] !== undefined)
    return cookies[cookieName]

  return null
}

export const useAuthStore = defineStore('auth', {
  state: (): State => ({
    loading: false,
    userInfo: ref(getCookieValue(ZEUS_USER_INFO)),
    token: ref(getCookieValue(ZEUS_TOKEN)),
    error: {
      data: {
        message: null,
      },
    },
    baseUrl: ref(null),
    loginEndpoint: ref(null),
  }),
  getters: {
    getToken: (state) => {
      return state.token ?? getCookie(ZEUS_TOKEN)
    },
    getExpiryDateTime(): Date {
      const tokenCandidate = this.getToken
      const decoded = tokenCandidate ? jwtDecode(this.getToken) : null

      if (decoded === null || decoded === undefined)
        return this.getCurrentDate

      return decoded ? new Date(decoded.exp * 1000) : this.getCurrentDate()
    },
    getCurrentDate(): Date {
      return new Date(Date.now())
    },
    getMinutesToExpiry(): number {
      const diffInMs: number = this.getExpiryDateTime - this.getCurrentDate
      if (diffInMs < 0)
        return -1
      return Number.parseInt(Math.abs(diffInMs) / 60000)
    },
    isExpired(): boolean {
      const expiryDateTime = this.getExpiryDateTime
      const currentDateTime = new Date(Date.now())
      return currentDateTime >= expiryDateTime
    },
    showExpiryWarning(): boolean {
      const MS_PER_MINUTE = 60000
      const timeToShowWarning = this.getExpiryDateTime - 15 * MS_PER_MINUTE
      return !this.isExpired && this.getMinutesToExpiry !== -1 && timeToShowWarning < Date.now()
    },
    getUserInfo: (state) => {
      if (state.userInfo === null)
        return getCookie(ZEUS_USER_INFO)

      try {
        return JSON.parse(state.userInfo)
      }
      catch (e) {
        return state.userInfo
      }
    },
  },
  actions: {
    initializeState() {
      const config = useRuntimeConfig()
      this.baseUrl = config.public?.baseUrl
      this.loginEndpoint = config.public?.loginEndpoint

      if (!this.baseUrl)
        throw new Error(t('login.invalidBaseUrl'))

      if (!this.loginEndpoint)
        throw new Error(t('login.invalidLoginEndpoint'))
    },
    expireCookies(): void {
      this.expireCookie(ZEUS_TOKEN)
      this.expireCookie(ZEUS_USER_INFO)
    },
    clearLocalStorage(): void {
      localStorage.clear()
    },
    clearCache(): void {
      // Cache keys
    },
    setCookie(key: string, value: string) {
      document.cookie = `${key}=${encodeURIComponent(value)}; Path=/; SameSite=Strict;`
    },
    expireCookie(key: string) {
      document.cookie = `${key}=null;Max-Age=0; Path=/; SameSite=Strict;`
    },
    async authenticateUser(userCredentials: UserPayloadInterface): Promise<void> {
      if (userCredentials === null)
        return Promise.reject(new Error(t('login.missingLoginData')))

      const { user, password } = userCredentials
      if (!user || !password)
        return Promise.reject(new Error(t('login.invalidUsernameOrPassword')))

      if (!this.baseUrl || !isValidUrl(this.baseUrl))
        return Promise.reject(new Error(t('login.invalidBaseUrl')))

      if (!this.loginEndpoint || !this.loginEndpoint.startsWith('/'))
        return Promise.reject(new Error(t('login.invalidLoginEndpoint')))

      try {
        const data = await $fetch(`${this.baseUrl}${this.loginEndpoint}`, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: {
            user,
            password,
          },
        })

        this.setCookie(ZEUS_TOKEN, data.authorization)
        this.token = data.authorization

        const userInfoObjects = {
          firstName: data.stafferDTO.firstName as string,
          lastName: data.stafferDTO.lastName as string,
          id: data.stafferDTO.id as number,
          roles: data.stafferDTO.roles as string[],
        }
        this.setCookie(ZEUS_USER_INFO, JSON.stringify(userInfoObjects))
        this.userInfo = userInfoObjects
      }
      catch (error) {
        this.error.data.message = error
        return Promise.reject(error)
      }
    },
    logUserOut() {
      this.clearCache()
      this.expireCookies()
      this.clearLocalStorage()
      navigateTo('/login')
    },
    async logUserIn(userPayload: UserPayloadInterface): Promise<void> {
      this.authenticateUser(userPayload)
        .then(async () => {
          await navigateTo('/')
        })
        .catch((err) => {
          console.error(err)
        })
    },
  },
})


if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as deleted_45topics_45eventsJRjcwOjfRWLkWpgkFdS0MAHqtXqJt_45ho0GzV8EbS4u4Meta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/deleted-topics-events.vue?macro=true";
import { default as indexyujy8zBGOh81BvVcGdxJj5tXRZtMNTHSU34qGM8alb4Meta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/index.vue?macro=true";
import { default as productGtld4V_6NFFYqvL7PjLXHEss_45Wf8UyN_45EcIWQS4g5m4Meta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/product.vue?macro=true";
import { default as release_45notes_45XRbKNoYTnmobqheJHKLWq6pEAVJwuuSNQ_450CF2CjyEMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/release-notes.vue?macro=true";
import { default as rubricqQyamjh8ZzQPpGUkjuOCEpYw7xwe64yRyeMBGU0_WjUMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/rubric.vue?macro=true";
import { default as schedule_45configurationsEpaOYTOi9_00BWRd7rs2Z5B_45sn_z8_5zRG9dxKqX3tMMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/schedule-configurations.vue?macro=true";
import { default as sectionLXHCwfIvkgpkkMbfEYJ4kUKr_45gyafFMZvozjG7Kmbn0Meta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/section.vue?macro=true";
import { default as staffer_45dispotypeb0URYETUou6IJ2yzFAqILUKq_45Xvj3NJp5VhiA2MaZyQMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/staffer-dispotype.vue?macro=true";
import { default as index3Tde7HjsqQPhffMWXT4Qni_45KVwyrQSALOpE9Fw4qbw8Meta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/staffer-rights/index.vue?macro=true";
import { default as teamOdCTQ8wRu1oDEwUXBa5FFdVXyWLro41bJluNH2x9PDsMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/team.vue?macro=true";
import { default as topicqYc63S3Lg0M_45uOvcpbicsI6hxxIrvWlt5yKzURVotbAMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/topic.vue?macro=true";
import { default as indexm_Dek9wwMiXH23h4fYrC1YxLvxT5WwYl_HjGQ9ZKOp0Meta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/index.vue?macro=true";
import { default as loginf6FEnyfplQ_45mMiFz9DQSnBc8p5t6wcbKD044pPfG4u4Meta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/login.vue?macro=true";
import { default as logoutYrO_giAYas1a5BmluAG5FOVlDg6eeAabdBSt7BceM5wMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/logout.vue?macro=true";
import { default as missing_45rights194DfqMXB_45PrtVwGb3DO_8vQ_cKCw40aePyqxnRp6WcMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/missing-rights.vue?macro=true";
import { default as calendarLcypzXN2sCHoY5RLF2iOckOEV2XzIzQWJJ4GUZ8KQcAMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/calendar.vue?macro=true";
import { default as contact_45person0tiUUZ5DPQD3BuP_auw37k_RaNk0xH0fby6PQY3nfOoMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/data-care/contact-person.vue?macro=true";
import { default as emailqyI8IsAWkvM1nEwDqwYcw4Lb4q54WKXAmKcyGpPZ260Meta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/data-care/email.vue?macro=true";
import { default as location_45namesMMzuFSoVJC9FDTu1ecVlZaA_hXwF_OXoHAEzEqK6p5cMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/data-care/location-names.vue?macro=true";
import { default as organisationsHgITcMIfdcv0WGR8iAdEUmaRhGs0VHRTisDzAnUweLkMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/data-care/organisations.vue?macro=true";
import { default as dossiersRF_45feR0iZiIiFN9gXIdTlQoj2_n6OOM9vkqUoAUzfIwMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/dossiers.vue?macro=true";
import { default as indexKTfdK4eyKNeH0jNwXCeEGvciWckp3s29wGr0eYWN4YoMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/index.vue?macro=true";
import { default as overviews4H9ZFbGU7svN82lO8VSiCeWHK_452CT9mqTxImduOvDhsMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/overviews.vue?macro=true";
import { default as previewsEpd5B8rh9YR2hEv9kX_uEHdOw1bdz61LjKrpQEGx3SYMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/previews.vue?macro=true";
import { default as staffer_45datarVnBreaOuQRFtcLzJ5B_LNFHW70rXKsGmEcYANcHEZEMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/staffer-teams/staffer-data.vue?macro=true";
import { default as staffer_45dispositionsfwcjUZwgwcRjfVI6N1xIJrnkiPPZSAzTm7pnSwP37gIMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/staffer-teams/staffer-dispositions.vue?macro=true";
import { default as team_45calendarofJNNDFX0YTzSCr7CJyG9WkKxI8ADsJyGOEiu5bwjk8Meta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/staffer-teams/team-calendar.vue?macro=true";
import { default as team_45setupmQLqqmf1NE7VAeupVCpjwzcqWgE4PwegIyCloFQS_45wYMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/staffer-teams/team-setup.vue?macro=true";
import { default as teamnvAhIeGHnHP722tudj9XfMu2yWuGEIR5eWT3ZYeOBC8Meta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/team.vue?macro=true";
export default [
  {
    name: "admin-deleted-topics-events",
    path: "/admin/deleted-topics-events",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/deleted-topics-events.vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/index.vue")
  },
  {
    name: "admin-product",
    path: "/admin/product",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/product.vue")
  },
  {
    name: "admin-release-notes",
    path: "/admin/release-notes",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/release-notes.vue")
  },
  {
    name: "admin-rubric",
    path: "/admin/rubric",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/rubric.vue")
  },
  {
    name: "admin-schedule-configurations",
    path: "/admin/schedule-configurations",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/schedule-configurations.vue")
  },
  {
    name: "admin-section",
    path: "/admin/section",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/section.vue")
  },
  {
    name: "admin-staffer-dispotype",
    path: "/admin/staffer-dispotype",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/staffer-dispotype.vue")
  },
  {
    name: "admin-staffer-rights",
    path: "/admin/staffer-rights",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/staffer-rights/index.vue")
  },
  {
    name: "admin-team",
    path: "/admin/team",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/team.vue")
  },
  {
    name: "admin-topic",
    path: "/admin/topic",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/topic.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/logout.vue")
  },
  {
    name: "missing-rights",
    path: "/missing-rights",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/missing-rights.vue")
  },
  {
    name: "planning-calendar",
    path: "/planning/calendar",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/calendar.vue")
  },
  {
    name: "planning-data-care-contact-person",
    path: "/planning/data-care/contact-person",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/data-care/contact-person.vue")
  },
  {
    name: "planning-data-care-email",
    path: "/planning/data-care/email",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/data-care/email.vue")
  },
  {
    name: "planning-data-care-location-names",
    path: "/planning/data-care/location-names",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/data-care/location-names.vue")
  },
  {
    name: "planning-data-care-organisations",
    path: "/planning/data-care/organisations",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/data-care/organisations.vue")
  },
  {
    name: "planning-dossiers",
    path: "/planning/dossiers",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/dossiers.vue")
  },
  {
    name: "planning",
    path: "/planning",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/index.vue")
  },
  {
    name: "planning-overviews",
    path: "/planning/overviews",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/overviews.vue")
  },
  {
    name: "planning-previews",
    path: "/planning/previews",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/previews.vue")
  },
  {
    name: "planning-staffer-teams-staffer-data",
    path: "/planning/staffer-teams/staffer-data",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/staffer-teams/staffer-data.vue")
  },
  {
    name: "planning-staffer-teams-staffer-dispositions",
    path: "/planning/staffer-teams/staffer-dispositions",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/staffer-teams/staffer-dispositions.vue")
  },
  {
    name: "planning-staffer-teams-team-calendar",
    path: "/planning/staffer-teams/team-calendar",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/staffer-teams/team-calendar.vue")
  },
  {
    name: "planning-staffer-teams-team-setup",
    path: "/planning/staffer-teams/team-setup",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/staffer-teams/team-setup.vue")
  },
  {
    name: "planning-team",
    path: "/planning/team",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/team.vue")
  }
]
/**
 * This file contains constants used throughout the application
 */

export const BASE_API = import.meta.env.VITE_BASE_API
export const DEV = 'dev'
export const TEST = 'test'
export const STAGE = 'stage'

export const ZEUS_TOKEN = 'zeus-token'
export const ZEUS_USER_INFO = 'zeus-user-info'

export const ADMIN = 'admin'
export const DELETE_OK = 'DELETE_OK'
export const DELETE_FAILED = 'DELETE_FAILED'
export const FETCH_OK = 'FETCH_OK'
export const UNEXPECTED_STATE = 'UNEXPECTED_STATE'
export const TC_POST_OK = 'TC_POST_OK'
export const TEAM_POST_OK = 'TEAM_POST_OK'
export const POST_OK = 'POST_OK'
export const FETCH_ERROR = 'FETCH_ERROR'
export const POST_ERROR = 'POST_ERROR'
export const NOT_POSTED = 'NOT_POSTED'
export const FAILED_REVERT = 'FAILED_REVERT'
export const FAILED_UPDATES_REVERTED = 'FAILED_UPDATES_REVERTED'
export const REVERT_OK = 'REVERT_OK'
export const OK = 'OK'
export const ERROR = 'ERROR'
export const PUT = 'PUT'
export const BLUR_EMIT = 'blur'
export const SET_CURRENT_TEAM_CONTAINER_ID_AND_OPEN_HISTORY_EMIT = 'setCurrentTeamContainerIdAndOpenHistory'
export const TOGGLE_HISTORY_EMIT = 'toggleHistory'
export const SET_CURRENT_TOPIC_CONTAINER_ID_AND_OPEN_HISTORY_EMIT = 'setCurrentTopicContainerIdAndOpenHistory'
export const SET_CURRENT_TOPIC_ID_AND_OPEN_HISTORY_EMIT = 'setCurrentTopicIdAndOpenHistory'
export const POST = 'POST'
export const GET = 'GET'
export const BEARER = 'Bearer'
export const DELETE = 'DELETE'

export const PUT_OK = 'PUT_OK'
export const NOT_PUT = 'NOT_PUT'

export const STAFFER_DISPOTYPE_RANK_MAX_CHARS = 3
export const STAFFER_DISPOTYPE_RANK_REPORT_MAX_CHARS = 3
export const STAFFER_DISPOTYPE_ALL_FIELDS_MIN_CHARS = 1

export const PRODUCT_ID_MAX_CHARS = 10
export const PRODUCT_SHORT_NAME_MIN_CHARS = 1
export const PRODUCT_SHORT_NAME_MAX_CHARS = 20
export const PRODUCT_LONG_NAME_MIN_CHARS = 1
export const PRODUCT_LONG_NAME_MAX_CHARS = 50
export const PRODUCT_LONG_NAME_EXTERNAL_DIFFERENT_MAX_CHARS = 50
export const PRODUCT_RANK_MAX_CHARS = 2
export const PRODUCT_QCODE_MAX_CHARS = 255
export const PRODUCT_MIMIR_PRODUCT_MAX_CHARS = 10

export const SECTION_LONG_NAME_DE_MIN_CHARS = 1
export enum InputWithTagsEmits {
  ADD_ITEM = 'addItem',
  DELETE_ITEM = 'deleteItem',
}

export enum StafferDispotypeErrorCode {
  REVERT_ERROR = 'REVERT_ERROR',
  FETCH_ERROR = 'FETCH_ERROR',
  POST_ERROR = 'POST_ERROR',
  MAPPING_ERROR = 'MAPPING_ERROR',
  PUT_ERROR = 'PUT_ERROR',
  FAILED_UPDATES_REVERTED = 'FAILED_UPDATES_REVERTED',
}

export enum StafferDispotypeCol {
  ID = 'id',
  ABSENT = 'absent',
  FREELANCE = 'freelance',
  FUNCTION = 'function',
  RANK = 'rank',
  RANK_REPORT = 'rankReport',
  SHORT_NAME_DE = 'shortNameDE',
  LONG_NAME_DE = 'longNameDE',
  SHORT_NAME_EN = 'shortNameEN',
  LONG_NAME_EN = 'longNameEN',
  MARKED_FOR_DELETION = 'markedForDeletion',
}

export enum StafferDispotypeInfoCode {
  STAFFER_DISPOTYPE_ERROR = 'STAFFER_DISPOTYPE_ERROR',
  STAFFER_DISPOTYPE_POST_OK = 'STAFFER_DISPOTYPE_POST_OK',
  STAFFER_DISPOTYPE_POST_ERROR = 'STAFFER_DISPOTYPE_POST_ERROR',
  STAFFER_DISPOTYPE_DELETE_OK = 'STAFFER_DISPOTYPE_DELETE_OK',
  INVALID_RANK = 'INVALID_RANK',
  INVALID_RANK_REPORT = 'INVALID_RANK_REPORT',
  INVALID_FIELD = 'INVALID_FIELD',
  INVALID_NEW_ROW = 'INVALID_NEW_ROW',
}

export enum SectionCol {
  ID = 'id',
  VALID = 'valid',
  SHORT_NAME_DE = 'shortNameDE',
  LONG_NAME_DE = 'longNameDE',
  SHORT_NAME_EN = 'shortNameEN',
  LONG_NAME_EN = 'longNameEN',
  TOPICS = 'topics',
  EMPTY = 'empty',
}

export enum SectionInfoCode {
  SECTION_DUPLICATE_ID = 'SECTION_DUPLICATE_ID',
  SECTION_MISSING_ID = 'SECTION_MISSING_ID',
  MISSING_LONG_NAME_DE = 'MISSING_LONG_NAME_DE',
  SECTION_ERROR = 'SECTION_ERROR',
  SECTION_POST_OK = 'SECTION_POST_OK',
  SECTION_POST_ERROR = 'SECTION_POST_ERROR',
  SECTION_DELETE_OK = 'SECTION_DELETE_OK',

}

export enum SectionErrorCode {
  REVERT_ERROR = 'REVERT_ERROR',
  FETCH_ERROR = 'FETCH_ERROR',
  FAILED_REVERT = 'FAILED_REVERT',
  FAILED_UPDATES_REVERTED = 'FAILED_UPDATES_REVERTED',
  POST_ERROR = 'POST_ERROR',
  UNEXPECTED_STATE = 'UNEXPECTED_STATE',
}

export enum SectionEmits {
  SET_CURRENT_SECTION_ID_AND_OPEN_HISTORY_EMIT = 'setCurrentSectionIdAndOpenHistory',
  TOGGLE_HISTORY = 'toggleHistory',
}

export enum ProductErrorCode {
  REVERT_ERROR = 'REVERT_ERROR',
  FETCH_ERROR = 'FETCH_ERROR',
  POST_ERROR = 'POST_ERROR',
  PUT_ERROR = 'PUT_ERROR',
  FAILED_REVERT = 'FAILED_REVERT',
  FAILED_UPDATES_REVERTED = 'FAILED_UPDATES_REVERTED',
}

export enum ProductContainerErrorCode {
  REVERT_ERROR = 'REVERT_ERROR',
  FETCH_ERROR = 'FETCH_ERROR',
  POST_ERROR = 'POST_ERROR',
  PUT_ERROR = 'PUT_ERROR',
  MAPPING_ERROR = 'MAPPING_ERROR',
}

export enum ProductInfoCode {
  UNSAVED_CHANGES = 'UNSAVED_CHANGES',
  PRODUCT_ERROR = 'PRODUCT_ERROR',
  PRODUCT_DUPLICATE_ID = 'PRODUCT_DUPLICATE_ID',
  PRODUCT_ID_MINIMUM_THREE_CHARS = 'PRODUCT_ID_MINIMUM_THREE_CHARS',
  MUST_SELECT_LANGUAGE = 'MUST_SELECT_LANGUAGE',
  MINIMUM_ONE_MEDIUM_ID = 'MINIMUM_ONE_MEDIUM_ID',
  PRODUCT_DUPLICATE_QCODE = 'PRODUCT_DUPLICATE_QCODE',
  INVALID_RANK = 'INVALID_RANK',
  PRODUCT_POST_OK = 'PRODUCT_POST_OK',
  INVALID_SHORT_NAME = 'INVALID_SHORT_NAME',
  MISSING_SHORT_NAME = 'MISSING_SHORT_NAME',
  INVALID_LONG_NAME = 'INVALID_LONG_NAME',
  MISSING_LONG_NAME = 'MISSING_LONG_NAME',
  MISSING_MEDIUM_ID = 'MISSING_MEDIUM_ID',
}

export enum ProductContainerInfoCode {
  MINIMUM_TWO_PRODUCTS = 'MINIMUM_TWO_PRODUCTS',
  INVALID_PRODUCT_CONTAINER_DATA = 'INVALID_PRODUCT_CONTAINER_DATA',
  UNSAVED_CHANGES = 'UNSAVED_CHANGES',
  PRODUCT_CONTAINER_ERROR = 'PRODUCT_CONTAINER_ERROR',
  PRODUCT_CONTAINER_DUPLICATE_ID = 'PRODUCT_CONTAINER_DUPLICATE_ID',
  PRODUCT_CONTAINER_ID_MINIMUM_THREE_CHARS = 'PRODUCT_CONTAINER_ID_MINIMUM_THREE_CHARS',
  DELETE_OK = 'DELETE_OK',
  PRODUCT_CONTAINER_POST_OK = 'PRODUCT_CONTAINER_POST_OK',
}

export enum ProductContainerEmits {
  SET_CURRENT_PRODUCT_CONTAINER_ID_AND_OPEN_HISTORY_EMIT = 'setCurrentProductContainerIdAndOpenHistory',
  UPDATE_IS_CHANGED_STATUS = 'updateIsChangedStatus',
}

export enum StafferDispotypeKey {
  ID = 'id',
  ABSENT = 'absent',
  FREELANCE = 'freelance',
  FUNCTION = 'function',
  RANK = 'rank',
  RANK_REPORT = 'rankReport',
  SHORT_NAME_DE = 'shortNameDE',
  LONG_NAME_DE = 'longNameDE',
  SHORT_NAME_EN = 'shortNameEN',
  LONG_NAME_EN = 'longNameEN',
  MARKED_FOR_DELETION = 'markedForDeletion',
  MARKED_AS_CHANGED = 'markedAsChanged',
  HIDE = 'hide',
}

export enum SectionKey {
  ID = 'id',
  VALID = 'valid',
  SHORT_NAME_DE = 'shortNameDE',
  LONG_NAME_DE = 'longNameDE',
  SHORT_NAME_EN = 'shortNameEN',
  LONG_NAME_EN = 'longNameEN',
  MARKED_FOR_DELETION = 'markedForDeletion',
  MARKED_AS_CHANGED = 'markedAsChanged',
  HIDE = 'hide',

}

export enum ProductContainerKey {
  ID = 'id',
  SHORT_NAME = 'shortName',
  LONG_NAME = 'longName',
  PRODUCT_IDS = 'productIds',
  MARKED_FOR_DELETION = 'markedForDeletion',
}

export enum ProductCol {
  ID = 'ID',
  QCODE = 'QCODE',
  SHORT_NAME = 'SHORT_NAME',
  LONG_NAME = 'LONG_NAME',
  LONG_NAME_EXTERNAL_DIFFERENT = 'LONG_NAME_EXTERNAL_DIFFERENT',
  RANK = 'RANK',
  LANGUAGE = 'LANGUAGE',
  MEDIUM_IDS = 'MEDIUM_IDS',
  USING_MEDIA_DISPOSITIONS = 'USING_MEDIA_DISPOSITIONS',
  MIMIR_PRODUCT = 'MIMIR_PRODUCT',
  VALID = 'VALID',
  HEIMSPIEL_AUDIENCE_QCODE = 'HEIMSPIEL_AUDIENCE_QCODE',
  PHONE = 'PHONE',
  EMPTY = 'EMPTY',
}

export enum ProductContainerCol {
  ID = 'ID',
  SHORT_NAME = 'SHORT_NAME',
  LONG_NAME = 'LONG_NAME',
  PRODUCTS = 'PRODUCTS',
}

export enum ProductKey {
  ID = 'id',
  QCODE = 'qcode',
  SHORT_NAME = 'shortName',
  LONG_NAME = 'longName',
  LONG_NAME_EXTERNAL_DIFFERENT = 'longNameExternalDifferent',
  RANK = 'rank',
  LANGUAGE = 'language',
  MEDIUM_IDS = 'mediumIds',
  USING_MEDIA_DISPOSITIONS = 'usingMediaDispositions',
  MIMIR_PRODUCT = 'mimirProduct',
  VALID = 'valid',
  DSV_AUDIENCE_QCODES = 'dsvAudienceQCodes',
  MARKED_AS_CHANGED = 'MARKED_AS_CHANGED',
  HIDE = 'HIDE',
}

export enum ReleaseNotesEmits {
  CHANGE = 'change',
  CLICK = 'click',
  ENTER_EDIT_MODE = 'enterEditMode',
  ADD_ENTRY_TO_UNSAVED_LIST = 'addEntryToUnsavedList',
  REMOVE_ENTRY_FROM_UNSAVED_LIST = 'removeEntryFromUnsavedList',
}

export enum ReleaseNoteKey {
  EDIT_MODE = 'editMode',
  DRAFT_ENTRY = 'draftEntry',
  IS_OPEN = 'isOpen',
  ENTRY = 'entry',
  DRAFT_RELEASE_DATE_TIME = 'draftReleaseDateTime',
}

export enum ReleaseNoteTypes {
  TOP = 'top',
  BODY = 'body',
  BOTTOM = 'bottom',
}

export enum RubricKey {
  ID = 'id',
  VALID = 'valid',
  LONG_NAME_DE = 'longNameDE',
  LONG_NAME_EN = 'longNameEN',
  LONG_NAME_ES = 'longNameES',
}

export enum TeamContainerKey {
  ID = 'id',
  SHORT_NAME = 'shortName',
  LONG_NAME = 'longName',
  TEAM_IDS = 'teamIds',
  MARKED_FOR_DELETION = 'markedForDeletion',
}

export enum TeamKey {
  SHORT_NAME = 'shortName',
  LONG_NAME = 'longName',
  MAJOR_RANK = 'majorRank',
  MINOR_RANK = 'minorRank',
  DEFAULT_CATEGORY_ID = 'defaultCategoryId',
  DEFAULT_GENRE_ID = 'defaultGenreId',
  PRODUCT_IDS = 'productIds',
  PHONE = 'phone',
  EMAIL = 'email',
  VALID = 'valid',
  MEDIA_OBJECT_PRODUCER = 'mediaObjectProducer',
  EDITORIAL = 'editorial',
}

export enum PopperSide {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left',
}
export enum DropDownChipQuantity {
  TOPIC = 1,
  // eslint-disable-next-line ts/no-duplicate-enum-values
  GENRE = 1,
  PRODUCTS = 3,
  // eslint-disable-next-line ts/no-duplicate-enum-values
  MEDIATYPE = 3,
}

export enum TeamEmits {
  SET_CURRENT_TEAM_ID_AND_OPEN_HISTORY_EMIT = 'setCurrentTeamIdAndOpenHistory',
  UPDATE_IS_CHANGED_STATUS = 'updateIsChangedStatus',
}

export enum TeamContainerCol {
  ID = 'ID',
  SHORT_NAME = 'SHORT_NAME',
  LONG_NAME = 'LONG_NAME',
  TEAMS = 'TEAMS',
}

export enum TeamCol {
  ID = 'ID',
  SHORT_NAME = 'SHORT_NAME',
  LONG_NAME = 'LONG_NAME',
  MAJOR_RANK = 'MAJOR_RANK',
  MINOR_RANK = 'MINOR_RANK',
  MEDIA_OBJECT_PRODUCER = 'MEDIA_OBJECT_PRODUCER',
  VALID = 'VALID',
  DEFAULT_CATEGORY_ID = 'DEFAULT_CATEGORY_ID',
  DEFAULT_GENRE_ID = 'DEFAULT_GENRE_ID',
  EDITORIAL = 'EDITORIAL',
  PRODUCT_IDS = 'PRODUCT_IDS',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  EMPTY = 'EMPTY',
}

export enum Sort {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}

export enum SortBy {
  NONE = 'NONE',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  SHORT_NAME_DE_ASC = 'SHORT_NAME_DE_ASC',
  SHORT_NAME_DE_DESC = 'SHORT_NAME_DE_DESC',
  SHORT_NAME_EN_ASC = 'SHORT_NAME_EN_ASC',
  SHORT_NAME_EN_DESC = 'SHORT_NAME_EN_DESC',
  SHORT_NAME_ASC = 'SHORT_NAME_ASC',
  SHORT_NAME_DESC = 'SHORT_NAME_DESC',
  LONG_NAME_ASC = 'LONG_NAME_ASC',
  LONG_NAME_DESC = 'LONG_NAME_DESC',
  LONG_NAME_DE_ASC = 'LONG_NAME_DE_ASC',
  LONG_NAME_DE_DESC = 'LONG_NAME_DE_DESC',
  LONG_NAME_EN_ASC = 'LONG_NAME_EN_ASC',
  LONG_NAME_EN_DESC = 'LONG_NAME_EN_DESC',
  TOPICS_ASC = 'TOPICS_ASC',
  TOPICS_DESC = 'TOPICS_DESC',
  MAJOR_RANK_ASC = 'MAJOR_RANK_ASC',
  MAJOR_RANK_DESC = 'MAJOR_RANK_DESC',
  MINOR_RANK_ASC = 'MINOR_RANK_ASC',
  MINOR_RANK_DESC = 'MINOR_RANK_DESC',
  DEFAULT_CATEGORY_ID_ASC = 'DEFAULT_CATEGORY_ID_ASC',
  DEFAULT_CATEGORY_ID_DESC = 'DEFAULT_CATEGORY_ID_DESC',
  DEFAULT_GENRE_ID_ASC = 'DEFAULT_GENRE_ID_ASC',
  DEFAULT_GENRE_ID_DESC = 'DEFAULT_GENRE_ID_DESC',
  PRODUCT_IDS_ASC = 'PRODUCT_IDS_ASC',
  PRODUCT_IDS_DESC = 'PRODUCT_IDS_DESC',
  PHONE_ASC = 'PHONE_ASC',
  PHONE_DESC = 'PHONE_DESC',
  EMAIL_ASC = 'EMAIL_ASC',
  EMAIL_DESC = 'EMAIL_DESC',
  VALID_ASC = 'VALID_ASC',
  VALID_DESC = 'VALID_DESC',
  MEDIA_OBJECT_PRODUCER_ASC = 'MEDIA_OBJECT_PRODUCER_ASC',
  MEDIA_OBJECT_PRODUCER_DESC = 'MEDIA_OBJECT_PRODUCER_DESC',
  EDITORIAL_ASC = 'EDITORIAL_ASC',
  EDITORIAL_DESC = 'EDITORIAL_DESC',
  TEAMS_ASC = 'TEAMS_ASC',
  TEAMS_DESC = 'TEAMS_DESC',
  QCODE_ASC = 'QCODE_ASC',
  QCODE_DESC = 'QCODE_DESC',
  RANK_ASC = 'RANK_ASC',
  RANK_DESC = 'RANK_DESC',
  LANGUAGE_ASC = 'LANGUAGE_ASC',
  LANGUAGE_DESC = 'LANGUAGE_DESC',
  MEDIUM_IDS_ASC = 'MEDIUM_IDS_ASC',
  MEDIUM_IDS_DESC = 'MEDIUM_IDS_DESC',
  LONG_NAME_EXTERNALLY_DIFFERENT_ASC = 'LONG_NAME_EXTERNALLY_DIFFERENT_ASC',
  LONG_NAME_EXTERNALLY_DIFFERENT_DESC = 'LONG_NAME_EXTERNALLY_DIFFERENT_DESC',
  ABSENT_ASC = 'ABSENT_ASC',
  ABSENT_DESC = 'ABSENT_DESC',
  FREELANCE_ASC = 'FREELANCE_ASC',
  FREELANCE_DESC = 'FREELANCE_DESC',
  FUNCTION_ASC = 'FUNCTION_ASC',
  FUNCTION_DESC = 'FUNCTION_DESC',
  RANK_REPORT_ASC = 'RANK_REPORT_ASC',
  RANK_REPORT_DESC = 'RANK_REPORT_DESC',
}

export enum TeamErrorCode {
  REVERT_ERROR = 'REVERT_ERROR',
  FETCH_ERROR = 'FETCH_ERROR',
  POST_ERROR = 'POST_ERROR',
  MAPPING_ERROR = 'MAPPING_ERROR',
}

export enum TeamContainerInfoCode {
  MINIMUM_TWO_TEAMS = 'MINIMUM_TWO_TEAMS',
  UNSAVED_CHANGES = 'UNSAVED_CHANGES',
  ERROR = 'ERROR',
  DUPLICATE_ID = 'DUPLICATE_ID',
  ID_MINIMUM_THREE_CHARS = 'ID_MINIMUM_THREE_CHARS',
}

export enum TeamInfoCode {
  DUPLICATE_ID = 'DUPLICATE_ID',
  ID_MINIMUM_THREE_CHARS = 'ID_MINIMUM_THREE_CHARS',
}

export enum RubricInfoCode {
  ID_MUST_BE_SET = 'ID_MUST_BE_SET',
  UNSAVED_CHANGES = 'UNSAVED_CHANGES',
}

export enum RubricErrorCode {
  REVERT_ERROR = 'REVERT_ERROR',
  FETCH_ERROR = 'FETCH_ERROR',
  POST_ERROR = 'POST_ERROR',
  MAPPING_ERROR = 'MAPPING_ERROR',
}

export enum TopicKey {
  ID = 'id',
  QCODE = 'qcode',
  SHORT_NAME_DE = 'shortNameDE',
  LONG_NAME_DE = 'longNameDE',
  SHORT_NAME_EN = 'shortNameEN',
  LONG_NAME_EN = 'longNameEN',
  SHORT_NAME_ES = 'shortNameES',
  LONG_NAME_ES = 'longNameES',
  MARKED_AS_CHANGED = 'markedAsChanged',
}

export enum TopicContainerNewRowEmits {
  REFRESH_TABLE = 'refreshTable',
  EMIT_TABLE = 'emitTable',
  SORT_TABLE = 'sortTable',
}

export enum TopicContainerTableCellEditableEmits {
  IS_EMPTY = 'isEmpty',
  BLUR = 'blur',
  FOCUS = 'focus',
}

export enum TopicContainerErrorCode {
  REVERT_ERROR = 'REVERT_ERROR',
  FETCH_ERROR = 'FETCH_ERROR',
  POST_ERROR = 'POST_ERROR',
  MAPPING_ERROR = 'MAPPING_ERROR',
  PUT_ERROR = 'PUT_ERROR',
}

export enum TopicInfoCode {
  POST_OK = 'POST_OK',
  FETCH_OK = 'FETCH_OK',
  INCONSISTENT_LONGNAME_SHORTNAME = 'INCONSISTENT_LONGNAME_SHORTNAME',
}

export enum TopicErrorCode {
  FETCH_ERROR = 'FETCH_ERROR',
  POST_ERROR = 'POST_ERROR',
  INCONSISTENT_LONGNAME_SHORTNAME = 'INCONSISTENT_LONGNAME_SHORTNAME',
}

export enum TopicContainerInfoCode {
  DELETE_TOPIC_CONTAINERS_HAD_ERRORS_REVERTING = 'DELETE_TOPIC_CONTAINERS_HAD_ERRORS_REVERTING',
  LANGUAGE_MISMATCH = 'LANGUAGE_MISMATCH',
  MINIMUM_ONE_RESSORT = 'MINIMUM_ONE_RESSORT',
  TOPIC_UNSAVED_CHANGES = 'TOPIC_UNSAVED_CHANGES',
  ID_MUST_BE_SET = 'ID_MUST_BE_SET',
  DELETE_OK = 'DELETE_OK',
  SAVE_OK = 'SAVE_OK',
  INVALID_ID = 'INVALID_ID',
  FAILED_UPDATES_REVERTED = 'FAILED_UPDATES_REVERTED',
  PUT_OK = 'PUT_OK',
  MUST_SELECT_LANGUAGE = 'MUST_SELECT_LANGUAGE',
}

export enum TableEmits {
  SORT = 'sort',
}

export enum DropDownEmits {
  UPDATE_SEARCH_TEXT = 'updateSearchText',
  UPDATE_ITEM_CANDIDATE = 'updateItemCandidate',
  DELETE_ITEM = 'deleteItem',
  ADD_ITEM_CANDIDATE = 'addItemCandidate',
  NO_ITEMS = 'noItems',
  CLICK = 'click',
}
export enum DropDownEvents {
  CandidateFirstItem = 'CANDIDATE_FIRST_ITEM',
  CandidateNextItem = 'CANDIDATE_NEXT_ITEM',
  CandidateLastItem = 'CANDIDATE_LAST_ITEM',
  CandidatePreviousItem = 'CANDIDATE_PREVIOUS_ITEM',
  CandidateNoItem = 'CANDIDATE_NO_ITEM',
  CandidateSpecificItem = 'CANDIDATE_SPECIFIC_ITEM',
}

export enum StafferRightsFlyByInfoCode {
  SETTINGS_WERE_RESET = 'SETTINGS_WERE_RESET',
  SETTINGS_WERE_SAVED = 'SETTINGS_WERE_SAVED',
  ADMIN_RIGHTS_REACTIVATED = 'ADMIN_RIGHTS_REACTIVATED',
}

export enum StafferRightsInfoCode {
  LOSE_OWN_ADMIN_RIGHTS_MSG = 'LOSE_OWN_ADMIN_RIGHTS_MSG',
  UNSAVED_CHANGES_MSG = 'UNSAVED_CHANGES_MSG',
}

export enum StafferRightsErrorCode {
  STAFFER_LIST_COULD_NOT_BE_FETCHED = 'STAFFER_LIST_COULD_NOT_BE_FETCHED',
  NO_USER_ID_ERROR = 'NO_USER_ID_ERROR',
  HISTORY_FETCH_FAILED = 'HISTORY_FETCH_FAILED',
  MISSING_SEARCH_STRING = 'MISSING_SEARCH_STRING',
  STAFFER_DATA_COULD_NOT_BE_FETCHED = 'STAFFER_DATA_COULD_NOT_BE_FETCHED',
  ROLE_LIST_FETCH_FAILED = 'ROLE_LIST_FETCH_FAILED',
  SAVE_FAILED = 'SAVE_FAILED',
}
export enum Roles {
  Produce = 'produce',
  Stafferdispo = 'stafferdispo',
  Assign = 'assign',
  Release = 'release',
  Publish = 'publish',
  Admin = 'admin',
  Videoadmin = 'videoadmin',
  Organisation = 'organisation',
}

export enum Languages {
  German = 'de',
  English = 'en',
  Spanish = 'es',
}
export const languages = [{ name: 'Deutsch', id: Languages.German }, { name: 'English', id: Languages.English }, { name: 'Español', id: Languages.Spanish }]

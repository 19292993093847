import type { RouteMiddleware } from '#app'
import type { RouteLocationNormalized } from 'vue-router'
import { storeToRefs } from 'pinia'
import { ADMIN } from '~/config/constants'
import { useAuthStore } from '~/store/auth'

export const mw: RouteMiddleware = async (to: RouteLocationNormalized) => {
  const authStore = useAuthStore()
  const { getUserInfo, isExpired } = storeToRefs(authStore)

  if (to.path === '/logout') {
    authStore.logUserOut()
    return navigateTo('/login')
  }

  if (isExpired.value && to?.name !== 'login') {
    authStore.logUserOut()
    abortNavigation()
    return navigateTo('/login')
  }

  if (!getUserInfo.value?.roles?.includes(ADMIN) && to?.name?.includes(ADMIN))
    return navigateTo(`/missing-rights?wanted=${to.name}`)
}

export default defineNuxtRouteMiddleware(mw)
